import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';

import { ClientOnly } from '@atc/react-client-only';

import {
    AccordionPanel,
} from 'reaxl';
import {
    FilterCheckboxes,
} from 'reaxl-filters';

import getFilterTitle from '@/utilities/getFilterTitle';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

function BuyOnlineFilterPlaceholder() {
    return (
        <>
            <div className="row skeleton__loading margin-top-3">
                <div className="col-xs-1">
                    <div className="skeleton__subtext skeleton__placeholder" />
                </div>
                <div className="col-xs-11">
                    <div className="skeleton__subtext skeleton__placeholder" />
                </div>
            </div>

            <div className="skeleton__loading margin-vertical-2">
                <div className="skeleton__spacer skeleton__placeholder padding-2" />
            </div>
        </>
    );
}

/**
*  BuyOnlineFilter presents the user with the ability to filter using home services attributes
*/
function BuyOnlineFilter({
    handleShowExpansion = () => { },
    onOptionChange = () => {},
    onOptionsClear = () => {},
}) {
    const device = useDevice();

    const filtersErrors = useSelector(srpFiltersDuck.selectors.getFiltersErrors);

    const homeServicesOptions = useSelector(srpFiltersDuck.selectors.getHomeServicesOptions);
    const homeServicesValues = useSelector(srpFiltersDuck.selectors.getHomeServicesValues);

    const homeServicesOptionsClone = JSON.parse(JSON.stringify(homeServicesOptions)); // copy so we don't mutate state

    const buyOnlineOption = {
        ...homeServicesOptionsClone,
        options: [...(homeServicesOptionsClone?.options?.filter((option) => option.value === 'BUY_ONLINE') || [])],
    };

    const buyOnlineValues = homeServicesValues.filter((item) => item === 'BUY_ONLINE');

    const filterValues = {
        homeServices: buyOnlineValues,
    };

    if (buyOnlineOption?.options?.[0]) {
        buyOnlineOption.options[0].label = 'Available to Buy Online';
    }

    const title = getFilterTitle('Buy 100% Online', buyOnlineValues);

    const filterName = 'buyOnline';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const isFiltersCollapsed = !!_get(device, 'lessThan.md', false);
    const isFiltersVisible = useSelector(srpFiltersDuck.selectors.getFiltersVisible);
    const renderControls = isExpanded && (!isFiltersCollapsed || isFiltersVisible) && Object.keys(filterValues).length !== 0;

    // expand the panel if active options inside of it
    useEffect(() => {
        if (buyOnlineOption.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buyOnlineOption.length]);

    return (
        <AccordionPanel
            contentPadding={5}
            data-cmp="BuyOnlineFilter"
            eventKey={filterName}
            title={title}
        >
            <ClientOnly fallback={renderControls ? <BuyOnlineFilterPlaceholder /> : null}>
                {!renderControls && <BuyOnlineFilterPlaceholder uniqueKey={filterName} />}
                {renderControls && (
                    <>
                        <FilterCheckboxes
                            key="localDeliveryCheckbox"
                            filter={buyOnlineOption}
                            onOptionChange={onOptionChange}
                            onOptionsClear={onOptionsClear}
                            values={filterValues}
                            errors={filtersErrors}
                            withAccordion={false}
                        />
                        <div className="text-gray text-size-200">Purchase your next car 100% online and get it delivered directly to your door or a local dealership.</div>
                    </>
                )}
            </ClientOnly>
        </AccordionPanel>
    );
}

export default BuyOnlineFilter;
