import indexArray from 'index-array';

import { translateKeys } from '@atc/bonnet-parameters';
import { getPath, pageNames } from '@atc/bonnet-paths';

import { BrowserStorage } from 'atc-js';

import {
    listingDataPreprocessing,
} from '@/utilities/dispatchUtils';
import formattedLastExecuteTime from '@/utilities/formattedLastExecuteTime';
import importAuthUtilityBundle from '@/utilities/importAuthUtilityBundle';

import { consumerBrowsingHistoryDuck, inventoryDuck, ownersDuck, savedInventoryDuck, userDuck } from '@/ducks';

import { srpSimilarListingsDuck, srpSupplementalDuck } from '@/ducks/srp';

import { viewCountFetcher } from '@/fetchers';

import vinValidator from '@/fetchers/ActiveListingsFetcher';
import ConsumerBrowserHistoryFetcher from '@/fetchers/ConsumerBrowserHistoryFetcher';
import SimilarListingsFetcher from '@/fetchers/SimilarListingsFetcher';
import fetchSearchBookmarks, { fetchShareBookmark, updateSearchBookmark } from '@/fetchers/srp/fetchSearchBookmarks';
import SupplementalSearchFetcher from '@/fetchers/srp/SupplementalSearchFetcher';

import { getSearchFromState } from '@/transformers';

const { VEHICLE_DETAILS } = pageNames;

const overridePaymentServicesUrlParameters = (url = '', overrideParams = {}, useDefaultRates = false) => {
    const psSearchParams = new URLSearchParams(url);
    if (useDefaultRates && !psSearchParams.has('defaultRate')) {
        psSearchParams.append('defaultRate', true);
    }
    Object.entries(overrideParams).forEach(([key, value]) => {
        psSearchParams.set(key, value);
    });

    return psSearchParams.toString();
};
/**
 * getSupplementalResults using ls or cs
 *
 * Fetching function that can accept an offset via the firstRecord parameter to load additional results
 */
function getSupplementalResultsData({
    channel = 'atc',
    displayPhoneNumber = true,
    firstRecord = '0',
    hideOwnerName = false,
    inventoryImageUrl = '',
}) {

    return async (dispatch, getState) => {
        // Set loading state for srpSupplementalDuck
        dispatch(srpSupplementalDuck.creators.setLoading());

        const state = getState();
        const searchQuery = getSearchFromState.getSRPSearch(state);
        const translatedQueryState = translateKeys(searchQuery, { target: 'lsc' });
        const fetchParams = {
            ...translatedQueryState,
            hideFilters: true,
            numRecords: 100,
            supplemental: true,
        };
        const fetcher = SupplementalSearchFetcher;

        delete fetchParams.newSearch;
        delete fetchParams.isNewSearch;

        if (firstRecord) {
            fetchParams.firstRecord = firstRecord;
        }

        if (channel) {
            fetchParams.channel = channel;
        }

        if (state?.query?.experience) {
            const { experience } = state.query;
            switch (experience) {
                case 'commercial': {
                    fetchParams.lifestyleCategory = 'Commercial';
                    break;
                }
                // case 'buy-online': {//do buy-online things}
                default:
            }
        }

        const {
            listings = [],
            owners = [],
            totalResultCount,
        } = await fetcher(fetchParams);

        const vdpUrl = await getPath(VEHICLE_DETAILS, searchQuery, {
            referrer: true,
            basePath: true,
            useUpdatedVdpPath: true,
        });

        listings.forEach((listing) => {
            listingDataPreprocessing({
                displayPhoneNumber,
                hideOwnerName,
                inventoryImageUrl,
                listing,
                owners,
                vdpUrl,
            });
        });

        if (listings.length > 0) {
            dispatch(ownersDuck.creators.addOwners(owners));
            dispatch(inventoryDuck.creators.addInventory(listings));
            dispatch(srpSupplementalDuck.creators.setCount(totalResultCount));

            if (firstRecord) {

                const { activeResults = [] } = srpSupplementalDuck.selectors.getDuckState(state);
                const newListings = [
                    ...activeResults,
                    ...listings.map((listing) => listing.id),
                ];
                dispatch(srpSupplementalDuck.creators.setActiveResults(newListings));

            } else {
                dispatch(srpSupplementalDuck.creators.setActiveResults(listings.map((listing) => listing.id)));
            }
        } else {
            dispatch(srpSupplementalDuck.creators.setActiveResults([]));
        }
    };
}

const getFilteredLeadCandidates = async ({ leadCandidates, channel, displayPhoneNumber, hideOwnerName, inventoryImageUrl }) => {
    const leadCandidateVins = leadCandidates.map((leadCandidate) => leadCandidate.vin);
    const vinsParam = leadCandidateVins.length > 1 ? leadCandidateVins.join(',') : leadCandidateVins[0];

    // validate active vins
    const { listings = [], owners = [] } = await vinValidator({ vinsParam, channel });

    // retrieve viewed listings from local storage
    const viewedListingsStorage = new BrowserStorage('VIEWED_CBH_VEHICLES', { persist: true });
    const viewedListings = viewedListingsStorage.getItem()?.listingIds || [];

    // filter viewed listings out of list of active lead candidates
    const filteredListings = listings.filter((listing) => !(viewedListings.includes(listing.id)));

    const vdpUrl = await getPath(VEHICLE_DETAILS, {}, {
        referrer: true,
        useUpdatedVdpPath: true,
    });

    filteredListings.forEach((listing) => {
        listingDataPreprocessing({
            displayPhoneNumber: true,
            hideOwnerName: false,
            inventoryImageUrl: '',
            listing,
            owners,
            vdpUrl,
        });
    });

    return { listings: filteredListings, owners };
};

const getConsumerBrowsingHistoryData = ({
    channel,
    pixAllId,
    enableViewCount,
    debug = false,
    displayPhoneNumber,
    hideOwnerName,
    inventoryImageUrl,
}) => async (dispatch, getState) => {
    const cbhLastFetchStorage = new BrowserStorage('CBH_LAST_FETCHED', { persist: true });
    const cbhLastFetched = cbhLastFetchStorage.getItem() || 0;
    const timeSinceLastFetched = Date.now() - cbhLastFetched;
    if (timeSinceLastFetched > 120000) {
        try {
            const { kbbTradeIns = [], leadCandidates = [] } = await ConsumerBrowserHistoryFetcher(pixAllId, debug, true);
            cbhLastFetchStorage.setItem(Date.now());
            let filteredLeadCandidates = {};
            if (leadCandidates.length) {
                filteredLeadCandidates = await getFilteredLeadCandidates({
                    leadCandidates,
                    channel,
                    displayPhoneNumber,
                    hideOwnerName,
                    inventoryImageUrl,
                });
                const dateRange = 7;
                const firstVehicleVin = filteredLeadCandidates?.listings?.[0]?.vin;

                if (enableViewCount && firstVehicleVin) {
                    const count = await viewCountFetcher({ vin: firstVehicleVin, dateRange });
                    const { ATC: atcCount = 0, KBB: kbbCount = 0 } = count;
                    filteredLeadCandidates.listings[0].viewCount = atcCount + kbbCount;
                }
            }

            dispatch(consumerBrowsingHistoryDuck.creators.loadCbhData({ kbbTradeIns, leadCandidates: filteredLeadCandidates }));
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error fetching lead candidates for still active alert: ', err);
        }
    }

};

export function setEmailAlertsSearch(data) {
    return (dispatch, getState) => {
        // Reset button state when setting new email search data
        const currentState = getState();
        const buttonState = savedInventoryDuck.selectors.getAnimatedButtonState(currentState);
        if (buttonState !== '') {
            dispatch({
                type: savedInventoryDuck.types.SET_ANIMATED_BUTTON_STATE,
                payload: '',
            });
        }
        dispatch(userDuck.creators.setSubscribeSearchItem({
            ...data,
            saveSearchObject: data.search || {},
        }));
    };
}

export function setEmailAlertsOnContainerLoad(data) {
    return (dispatch) => {
        dispatch(userDuck.creators.setSearchBookmarks({
            ...data,
            saveSearchObject: data.search || {},
        }));
    };
}

const handleSearchRedirect = (isLoggedIn, navigateToSrp) => async (dispatch, getState) => {

    const state = getState();
    let requestValues = {};
    const {
        query: {
            bookmarkId = null,
        },
    } = state;

    const { getCognitoIdToken } = await importAuthUtilityBundle();
    const idtoken = await getCognitoIdToken().catch((_) => null);

    try {
        let searchBookmarks;
        if (isLoggedIn) {
            ({ myAtcSearchViewBeans: searchBookmarks } = await fetchSearchBookmarks(bookmarkId, idtoken));
        } else {
            ({ myAtcSearchViewBeans: searchBookmarks } = await fetchShareBookmark(bookmarkId, idtoken));
        }

        if (!(searchBookmarks && searchBookmarks.length > 0)) {
            throw new Error('Bookmark not found');
        }
        const search = searchBookmarks[0];
        requestValues = JSON.parse(decodeURIComponent(search.searchResultsURL));
    } catch (e) {
        requestValues = {};
    }
    navigateToSrp({
        filtersValues: requestValues,
        isNewSearch: true,
        resetPagination: true,
    });
};

const loadSavedSearchBookmark = () => async (dispatch, getState) => {
    const state = getState();
    const {
        user: {
            bookmarks: {
                search,
            },
        },
        query: {
            searchBookmarkId = null,
        },
    } = state;

    if (!searchBookmarkId) {
        return;
    }

    const { getCognitoIdToken } = await importAuthUtilityBundle();
    const idtoken = await getCognitoIdToken().catch((_) => null);

    const { myAtcSearchViewBeans: searchBookmarks } = await fetchSearchBookmarks(searchBookmarkId, idtoken);
    if (searchBookmarks && searchBookmarks.length > 0) {
        const {
            bookmarkId,
            textModalDisclaimer,
            bookmarkTitle,
            saveSearchInstrText,
        } = searchBookmarks[0];

        dispatch(userDuck.creators.setSavedSearchItem({
            ...search,
            textModalDisclaimer,
            title: bookmarkTitle,
            saveSearchInstrText,
        }));

        await updateSearchBookmark({
            bookmarkId,
            bookmarkTitle,
            searchName: bookmarkTitle,
            lastExecuted: formattedLastExecuteTime(new Date()),
        }, idtoken);
    }

};

async function fetchSimilarListings({
    dispatch,
    search,
    hideOwnerName,
    inventoryImageUrl,
    displayPhoneNumber,
    enableFBAPaymentCalc,
    paymentDealerId = false,
    paymentPartnerId = false,
}) {
    dispatch(srpSimilarListingsDuck.creators.setLoading());

    const response = await SimilarListingsFetcher(search);

    if (response?.listings) {
        const vdpUrl = await getPath(VEHICLE_DETAILS, {}, {
            referrer: true,
            useUpdatedVdpPath: true,
        });

        const { listings, owners } = response;

        Object.values(listings).forEach((listing) => {
            listingDataPreprocessing({
                displayPhoneNumber,
                hideOwnerName,
                inventoryImageUrl,
                listing,
                owners,
                vdpUrl,
            });

            const overridePaymentCalcParams = {};

            const overrideIncentivesParams = {};

            if (enableFBAPaymentCalc) {
                if (paymentDealerId) {
                    overridePaymentCalcParams.dealerId = paymentDealerId;
                    overridePaymentCalcParams.defaultRate = false;
                    overrideIncentivesParams.dealerId = paymentDealerId;
                }

                if (paymentPartnerId) {
                    overridePaymentCalcParams.partnerId = paymentPartnerId;
                    overrideIncentivesParams.partnerId = paymentPartnerId;
                }

                const updatedPSUrl = overridePaymentServicesUrlParameters(listing.paymentServices.paymentCalculationUrl, overridePaymentCalcParams, false);
                const updatedIncentivesUrl = overridePaymentServicesUrlParameters(listing.paymentServices.incentivesUrl, overrideIncentivesParams, false);

                listing.paymentServices.paymentCalculationUrl = updatedPSUrl;
                listing.paymentServices.incentivesUrl = updatedIncentivesUrl;
            }
        });

        const similarListingOwners = indexArray(owners, 'id');

        dispatch(inventoryDuck.creators.addInventory(listings));
        const similarListingIdsArray = listings.map((listing) => listing.id);
        dispatch(ownersDuck.creators.addOwners(similarListingOwners));
        dispatch(srpSimilarListingsDuck.creators.setActiveResults(similarListingIdsArray));
    } else {
        dispatch(srpSimilarListingsDuck.creators.setActiveResults([]));
    }
}

export function getSimilarListings({
    channel,
    displayPhoneNumber,
    excludedListingIds = [],
    hideOwnerName = false,
    inventoryImageUrl,
    ignorePriority,
    query,
    vhrProviders = '',
    enableFBAPaymentCalc,
    paymentDealerId,
    paymentPartnerId,
    enablePremiumSpotlight = false,
}) {
    const criteria = {
        ...query,
        bodyStyleCode: query.vehicleStyleCode,
        classSeriesCode: query.seriesCode,
        searchRadius: query.searchRadius && query.searchRadius < 100 ? query.searchRadius : 100,
        numRecords: 20,
        // Override Preview channel to behave as ATC for testing L2 Retailing
        channel: channel === 'ATC,PREVIEW' ? 'ATC' : channel,
        ignorePriority,
        includeVehicleHighlights: enablePremiumSpotlight,
    };

    if (excludedListingIds.length > 0 && excludedListingIds.length <= 25) {
        Object.assign(criteria, { excludedListingId: excludedListingIds });
    }

    if (vhrProviders) {
        Object.assign(criteria, { vhrProvider: vhrProviders });
    }

    if (query?.experience && query.experience === 'commercial') {
        criteria.lifestyleCategory = 'Commercial';
        criteria.filterByCategory = 'COMMERCIAL';
        delete criteria.experience;
    }

    return async (dispatch) => {
        await fetchSimilarListings({
            dispatch,
            search: criteria,
            hideOwnerName,
            inventoryImageUrl,
            displayPhoneNumber,
            enableFBAPaymentCalc,
            paymentDealerId,
            paymentPartnerId,
        });
    };
}

export default {
    getSupplementalResultsData,
    getSimilarListings,
    setEmailAlertsSearch,
    setEmailAlertsOnContainerLoad,
    loadSavedSearchBookmark,
    handleSearchRedirect,
    getConsumerBrowsingHistoryData,
};
