import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import { srpFixedAdDuck } from '@/ducks/srp';

import DisableAdsFeatureFlag from '@/containers/global/DisableAdsFeatureFlag';

export function FixedAdWaypointContainer({
    cmp,
    onLeave,
    onEnter,
    onPositionChange,
}) {
    const dispatch = useDispatch();
    const actions = useMemo(() => ({
        showFixedAd: () => dispatch(srpFixedAdDuck.creators.setKeys({
            showFixedAd: true,
        })),
        hideFixedAd: () => dispatch(srpFixedAdDuck.creators.setKeys({
            showFixedAd: false,
        })),
        refreshFixedAd: (adPos) => dispatch(srpFixedAdDuck.creators.setKeys({
            adPos,
        })),
    }), [dispatch]);

    const handleOnLeave = useCallback((props) => {
        if (onLeave) {
            onLeave({ actions, ...props });
        }
    }, [actions, onLeave]);

    const handleOnEnter = useCallback((props) => {
        if (onEnter) {
            onEnter({ actions, ...props });
        }
    }, [actions, onEnter]);

    const handleOnPositionChange = useCallback((props) => {
        if (onPositionChange) {
            onPositionChange({ actions, ...props });
        }
    }, [actions, onPositionChange]);

    const dataCmp = `${cmp}FixedAdTrigger`;

    return (
        <DisableAdsFeatureFlag>
            <Waypoint
                data-cmp={dataCmp}
                onLeave={handleOnLeave}
                onEnter={handleOnEnter}
                onPositionChange={handleOnPositionChange}
            />
        </DisableAdsFeatureFlag>
    );
}

export default FixedAdWaypointContainer;
