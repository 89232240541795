import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import getConfig from 'next/config';

import { eventBus } from '@atc/cai-event-bus';

import { useFeatures } from 'reaxl-features';
import {
    ConnectedMyWalletModal,
    ConnectedWalletPaymentDetailsModal,
    ownerDetailsFetcher,
    VrsServiceClient,
    WalletManagerContainer,
} from 'reaxl-wallet';

import {
    WALLET_VALIDATE_DEALS_BY_VIN_COMPLETED,
    WALLET_VALIDATE_DEALS_BY_VIN_REQUEST,
} from 'wallet-manager';

import {
    currentPageNameDuck,
    dealsDuck,
    interestRateDuck,
    inventoryDuck,
    paymentsDuck,
    userAgentDuck,
    userDuck,
} from '@/ducks';

import { srpFiltersDuck, srpReuseActiveStateDuck } from '@/ducks/srp';
import { vdpActiveInteractionDuck, vdpResultsDuck } from '@/ducks/vdp';

// fetchers
import tradeInFetcher from '@/fetchers/ConsumerBrowserHistoryFetcher';
import creditApplicationsFetcher from '@/fetchers/CreditApplicationsFetcher';
import creditVerificationFetcher from '@/fetchers/creditVerificationFetcher';
import EmailOwnerFetcher from '@/fetchers/EmailOwnerFetcher';
import IcoFetcher from '@/fetchers/IcoFetcher';
import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';
import VrsServiceFetcher from '@/fetchers/VrsServiceFetcher';

import useSrpNavigation from '@/hooks/useSrpNavigation';

const vrsServices = new VrsServiceClient({ vrsFetcher: VrsServiceFetcher });

function MyWalletContainer({
    blockTradeInFetch = false,
}) {
    const dispatch = useDispatch();

    const { visible: filtersVisible } = useSelector(srpFiltersDuck.selectors.getDuckState);
    const currentPageName = useSelector(currentPageNameDuck.selectors.getDuckState);
    const isBot = useSelector(userAgentDuck.selectors.isBot);

    const navigateToSrp = useSrpNavigation();

    const { publicRuntimeConfig } = getConfig();

    const {
        wallet_payment_option: [enablePaymentOption, { disable_lease: isLeaseDisabled }],
        wallet_email_capture: [enableWalletEmailCapture],
        enable_deals_duck: [isDealsDuckEnabled],
    } = useFeatures(['wallet_payment_option', 'wallet_email_capture', 'enable_deals_duck']);

    const handleSearchByBudget = useCallback(async (maxPriceValue, walletPaymentOption) => {

        const newFiltersValues = {
            maxPrice: maxPriceValue,
        };

        if (enablePaymentOption && walletPaymentOption === 'lease') {
            newFiltersValues.listingTypes = 'NEW';
        }

        // to ensure srpFiltersDuck get new maxPrice value
        await dispatch(srpReuseActiveStateDuck.creators.setFalse());
        navigateToSrp({
            filtersValues: newFiltersValues,
            isNewSearch: true,
            resetPagination: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigateToSrp, dispatch]);

    const onSacaAppClick = () => {
        dispatch(vdpActiveInteractionDuck.creators.setKeys({
            showFinanceAppModal: true,
        }));
    };

    const walletProps = {
        vdpResultsDuck,
        paymentsDuck,
        ...(isDealsDuckEnabled && {
            dealsDuck,
        }),
        userDuck,
        currentPageNameDuck,
        enableWalletEmailCapture,
        inventoryDuck,
        interestRateDuck,
        // disable duplicate cbh fetch from wallet on srp
        blockTradeInFetch,
        tradeInFetcher,
        IcoFetcher,
        vrsServicesFetcher: vrsServices,
        creditVerificationFetcher,
        creditApplicationsFetcher,
        EmailOwnerFetcher,
        paymentFetcher: PaymentCalculationFetcher,
        pageName: currentPageName,
        onSacaAppClick: () => onSacaAppClick(),
        onSearchByBudget: useCallback(async ({ maxPrice, walletPaymentOption }) => {
            await handleSearchByBudget(maxPrice, walletPaymentOption);
        }, [handleSearchByBudget]),
        onSearchAvailVehicles: useCallback(async () => {
            if (filtersVisible) {
                await Promise.resolve(dispatch(srpFiltersDuck.creators.hideFilters()));
            }
        }, [filtersVisible, dispatch]),
    };

    if (isBot) {
        delete walletProps.tradeInFetcher;
    }

    const appFetchers = {
        ownerDetailsFetcher: ownerDetailsFetcher(),
    };

    const validateDealsByVin = (vins = []) => {
        const vinsWithVdpHref = vins.reduce((acc, vin, key) => {
            const vinObject = { vin };

            acc.push(vinObject);

            return acc;
        }, []);
        eventBus.emit(WALLET_VALIDATE_DEALS_BY_VIN_COMPLETED, vinsWithVdpHref);
    };

    useEffect(() => {
        eventBus.on(WALLET_VALIDATE_DEALS_BY_VIN_REQUEST, validateDealsByVin);
        return () => {
            eventBus.off(WALLET_VALIDATE_DEALS_BY_VIN_REQUEST, validateDealsByVin);
        };
    }, []);

    return (
        <>
            <WalletManagerContainer
                paymentsDuck={paymentsDuck}
                env={publicRuntimeConfig?.environment === 'prod' ? 'prod' : 'staging'}
                fetchers={appFetchers}
                isLeaseDisabled={isLeaseDisabled}
            />
            <ConnectedMyWalletModal {...walletProps} />
            <ConnectedWalletPaymentDetailsModal
                paymentsDuck={paymentsDuck}
                vdpResultsDuck={currentPageName === 'vdp' && vdpResultsDuck}
            />
        </>
    );
}

export default MyWalletContainer;
