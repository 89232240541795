import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { formattedNumber } from 'atc-js';

import {
    AccordionPanel,
    Select,
} from 'reaxl';
import { FilterTitle } from 'reaxl-filters';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function FuelEconomyFilter({
    onOptionChange = () => { },
    handleShowExpansion = () => { },
}) {
    const fuelTypeValues = useSelector(srpFiltersDuck.selectors.getFuelTypeValues);

    const fuelEconomyValues = useSelector(srpFiltersDuck.selectors.getFuelEconomyValue);
    const fuelEconomyOptions = useSelector(srpFiltersDuck.selectors.getFuelEconomyOptions);

    const electricMileRangeValues = useSelector(srpFiltersDuck.selectors.getElectricMileRangeValues);

    // MPG is disabled if no numerical options are available or only Electric fuel type is selected
    const filteredFuelEconomyOptions = (fuelEconomyOptions?.options
        && fuelEconomyOptions.options.filter((option) => option.label !== 'Any')) || [];
    const isFuelEconomyDisabled = (filteredFuelEconomyOptions.length > 0
        && filteredFuelEconomyOptions.filter((option) => option.disabled).length === filteredFuelEconomyOptions.length)
        || (fuelTypeValues.length === 1 && fuelTypeValues.includes('ELE'));

    const filterName = 'mpgRange';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(fuelEconomyValues).length !== 0 || Object.keys(electricMileRangeValues).length !== 0;
    const title = (
        <FilterTitle
            key="fuelEconomyFilterTitle"
            title="Fuel Economy"
        />
    );

    // expand the panel if active options inside of it
    useEffect(() => {
        if (fuelEconomyValues !== '0') {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fuelEconomyValues]);

    const getFilterOptions = (options) => (options ? options.map((option) => ({
        ...option,
        label: option.count || option.count === 0 ? `${option.label} (${formattedNumber({ value: option.count })})` : option.label,
    })) : []);

    const dropDowns = (
        <div className="row">
            <div className="col-xs-12">
                <Select
                    disabled={!fuelEconomyOptions?.options || isFuelEconomyDisabled}
                    onChange={(event) => onOptionChange(event, fuelEconomyOptions)}
                    options={getFilterOptions(fuelEconomyOptions.options)}
                    name={filterName}
                    value={fuelEconomyValues}
                    aria-label="Miles Per Gallon"
                />
            </div>
        </div>
    );

    return (
        <AccordionPanel
            data-cmp="FuelEconomyFilter"
            eventKey={filterName}
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && dropDowns}
        </AccordionPanel>
    );
}

export default FuelEconomyFilter;
