import React, { memo, useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import clsx from 'clsx';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { useDevice } from '@bonnet/next/device';

import { Button, Link } from 'reaxl';
import { sendClick } from 'reaxl-analytics';
import { commercialEntryPoint } from 'reaxl-analytics-handlers';
import { PromoCard } from 'reaxl-listing';

import getPathSRP from '@/utilities/getPathSRP';

import { queryDuck } from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';

function CommercialVehicleCard() {

    const filterValues = useSelector(srpFiltersDuck.selectors.getRequestValues);
    const device = useDevice();
    const isGreaterThanXs = _get(device, 'greaterThan.xs', false);

    const query = useSelector(queryDuck.selectors.getDuckState);
    const [commercialPath, setCommercialPath] = useState('');

    const handleSearchCTAClick = useCallback((event) => {
        sendClick(commercialEntryPoint, event, {
            cmp: 'cta::search-commercial-vehicles',
            eventSource: 'button',
            filterValues,
        });
    }, [filterValues]);

    useEffect(() => {
        const commercialQuery = { ...query };
        delete commercialQuery.makeCode;
        delete commercialQuery.modelCode;
        delete commercialQuery.trimCode;
        delete commercialQuery.vehicleStyleCode;
        const generateCommercialSrpPath = async () => {
            const path = await getPathSRP({ ...commercialQuery, experience: 'commercial' }, { basePath: true });
            setCommercialPath(path);
        };

        if (!_isEmpty(commercialQuery)) {
            generateCommercialSrpPath();
        }
    }, [query]);

    const renderSearchCTA = (
        <div className="padding-horizontal-1">
            We&apos;ve rounded up a few choices that could be a perfect match.
            <Link href={commercialPath}>
                <Button
                    bsStyle="secondary"
                    bsSize="large"
                    className={clsx('col-xs-12 padding-horizontal-3 margin-vertical-5', {
                        'text-size-300': isGreaterThanXs, 'text-size-400': !isGreaterThanXs,
                    })}
                    onClick={handleSearchCTAClick}
                >
                    Search Commercial Vehicles
                </Button>
            </Link>

        </div>
    );

    return (
        <PromoCard
            data-cmp="commercialVehicleCard"
            bodyClassName="col-xs-12 padding-top-sm-6 padding-top-3 padding-horizontal-4 fade-in"
            className="col-xs-12 col-sm-4 display-flex"
            heading="Considering Commercial?"
            imgHeight="99px"
            imgWidth="220px"
            imgSrc="/content/static/img/commercial/commercial_truck_trans_sized.png"
            subText={renderSearchCTA}
        />
    );
}
export default memo(CommercialVehicleCard);
