import React, { useState } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';

import {
    SuggestedFiltersModal,
} from 'reaxl-molecules';

import {
    srpFiltersDuck,
    srpSuggestedMakeModelValuesDuck,
} from '@/ducks/srp';

import useSrpNavigation from '@/hooks/useSrpNavigation';

function ModelRebadgeModalContainer() {
    const dispatch = useDispatch();
    const COOKIE_NAME = 'doNotShowSuggested';

    const filtersValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);
    const selectedMakes = _get(filtersValues, 'makeCodeList', []);
    const suggestedModels = useSelector(srpSuggestedMakeModelValuesDuck.selectors.getDuckState);
    const showSuggestedFiltersModal = Object.keys(suggestedModels).length > 0 && !!suggestedModels.makeModelDescription;

    const navigateToSrp = useSrpNavigation();
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [cookies, setCookie] = useCookies([COOKIE_NAME]);

    let doNotShowSuggestedCookie = Object.values(cookies[COOKIE_NAME] || {});

    const handleToggleShowOption = () => {

        setIsCheckboxChecked(!isCheckboxChecked);

        const suggestModels = suggestedModels.models.map((model) => model.suggestedModelCode);

        if (isCheckboxChecked) {
            doNotShowSuggestedCookie = doNotShowSuggestedCookie.concat(suggestModels);
        } else {
            doNotShowSuggestedCookie = doNotShowSuggestedCookie.filter((model) => !suggestModels.includes(model));
        }

        setCookie(COOKIE_NAME, doNotShowSuggestedCookie, { path: '/' });
    };

    const handleSuggestedFiltersAgree = () => {

        suggestedModels.models.forEach((item) => {
            // Only update selected makes that have not already been selected in the filters
            if (!selectedMakes.includes(item.suggestedMakeCode)) {
                dispatch(srpFiltersDuck.creators.selectFilter({
                    filter: { name: 'makeCodeList' },
                    value: item.suggestedMakeCode,
                    isMultiple: true,
                }));
            }

            dispatch(srpFiltersDuck.creators.selectFilter({
                filter: { name: `modelCodeList|${item.suggestedMakeCode}` },
                value: item.suggestedModelCode,
                isMultiple: true,
            }));
        });

        // push new instance of srp to history
        navigateToSrp();

    };

    const handleSuggestedFiltersDisagree = () => { };

    return showSuggestedFiltersModal && (
        <SuggestedFiltersModal
            {...suggestedModels}
            onHide={handleSuggestedFiltersDisagree}
            onToggleShowOption={handleToggleShowOption}
            onSuggestedFiltersAgree={handleSuggestedFiltersAgree}
            onSuggestedFiltersDisagree={handleSuggestedFiltersDisagree}
        />
    );
}

export default ModelRebadgeModalContainer;
