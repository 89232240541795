import React, { memo, useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import _get from 'lodash/get';

import { useDevice } from '@bonnet/next/device';
import { usePageEvent } from '@bonnet/next/use-page-event';

import { SponsoredText } from 'reaxl';
import { sendClick, sendImpressions } from 'reaxl-analytics';
import { telMetricsPhoneTextClick } from 'reaxl-analytics-handlers';
import { useFeatures } from 'reaxl-features';
import { InventoryListingPlaceholder } from 'reaxl-listing';

// Utilities
import { getDealerDetailUrl } from '@/utilities/ddpUrlBuilder';
import { getAlphaClickType, getAlphaListingType } from '@/utilities/getAlphaTaggingType';
import { saveAlphaListing } from '@/utilities/saveListing';
import vdpUrlBuilder, { getAbsolutePathWithListingId } from '@/utilities/vdpUrlBuilder';

import { compareListingsDrawerClick } from '@/analytics/inventoryAnalyticsHandlers';
import { alphaImpression } from '@/analytics/srpAnalyticsHandlers';

import {
    compareListingsDuck,
    paymentsDuck,
    savedInventoryDuck,
    userPreferencesDuck,
} from '@/ducks';

import {
    srpActiveEmailListingDuck,
    srpActiveInteractionDuck,
    srpSponsorshipDuck,
} from '@/ducks/srp';

// Fetcher

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

import useVdpNavigation from '@/hooks/useVdpNavigation';

import AlphaModule from '@/modules/AlphaModule';

import AlphaShowcase from '@/components/AlphaShowcase';

import AlphaHandlers from './analytics/AlphaHandlers';

/*
 * The AlphaShowcaseContainer will display either the InventoryListingAlpha or OwnerAlphaListing from reaxl-organisms
 * based on the data it receives.
 */
function AlphaShowcaseContainerComponent() {
    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);
    const {
        alpha_showcase: [, { owner_inventory_handler: enableOwnerInventoryHandler }],
        SPONSORSHIP_RESULTS_ONLY: [sponsorshipResultsOnly],
        ddp_link: [isDdpLinkEnabled],
        nds: ndsFeature,
        my_wallet: [enableMyWallet],
        personalization: [isPersonalizationEnabled, { listings_saved: authenticatedSavesOnly }],
        retail_engine: [enableRetailEngine],
    } = useFeatures([
        'alpha_showcase',
        'SPONSORSHIP_RESULTS_ONLY',
        'ddp_link',
        'nds',
        'my_wallet',
        'personalization',
        'retail_engine',
    ]);

    const { onPageEnter } = usePageEvent('alphaShowcase');
    const navigateToVdp = useVdpNavigation();
    const dispatch = useDispatch();

    const compareToggle = useSelector(userPreferencesDuck.selectors.getCompareToggle);
    const showAlphaWalletPayment = useSelector((state) => paymentsDuck.selectors.getMyWalletInteraction(state, 'paymentEmphasis'));
    const compareListingIds = useSelector(compareListingsDuck.selectors.getActiveListingIds);
    const isSponsorshipSelected = useSelector(srpSponsorshipDuck.selectors.getDuckState);
    const savedListingIds = useSelector(savedInventoryDuck.selectors.getSavedInventoryIds);
    const isAlphaMarket = useSelector(AlphaModule.duck.selectors.isAlphaMarket);
    const isOwnerAlpha = !useSelector(AlphaModule.duck.selectors.isListingAlpha);
    const isLoadingAlpha = useSelector(AlphaModule.duck.selectors.isLoading);
    const similarListingsCount = useSelector(AlphaModule.duck.selectors.getSimilarListingsCount);
    const carouselListings = useSelector(AlphaModule.duck.selectors.getActiveInventory, (oldVal, newVal) => oldVal[0]?.id === newVal[0]?.id);
    const showcaseListing = carouselListings[0];
    const owner = useSelector(AlphaModule.duck.selectors.getOwner, (oldVal, newVal) => oldVal.id === newVal.id);
    const hasAlpha = !!owner && owner.name;

    /* default values */
    const dealerDetailUrl = _get(getDealerDetailUrl(owner), 'href', '');
    const listingId = _get(showcaseListing, 'id', '');
    const ownerId = _get(owner, 'id', '');
    const vdpWebsite = vdpUrlBuilder({ baseUrl: _get(showcaseListing, 'vdpBaseUrl'), clickType: getAlphaClickType(isAlphaMarket, isOwnerAlpha) });

    /* ui context props*/
    const alphaContext = 'inventory';
    const uiContext = isOwnerAlpha ? 'owner' : alphaContext;
    const parentId = getAlphaListingType(isAlphaMarket, isOwnerAlpha);

    const orientation = isXs ? 'portrait' : 'landscape';
    const ownerHasLocation = Object.keys(_get(owner, 'location', {})).length > 0;

    /* on initial page load & when the page instance id changes, fire an impression */
    onPageEnter(() => {
        if (ownerId) {
            sendImpressions({
                name: alphaImpression,
            });
        }
    });

    const updateMyWallet = useCallback((state) => dispatch(paymentsDuck.creators.updateMyWallet(state)), [dispatch]);
    const updateListingPayments = useCallback((state) => dispatch(paymentsDuck.creators.updateListingPayments(state)), [dispatch]);

    /*
     * import alpha analytics handlers
     */
    const handleAlphaWalletClick = AlphaHandlers.useAlphaWalletClick(updateMyWallet, showcaseListing, parentId);

    const handleCollapsibleHeaderClick = AlphaHandlers.useCollapsibleHeaderClick(showcaseListing, parentId);

    const handleGetDirectionsClick = AlphaHandlers.useGetDirectionsClick(showcaseListing, owner, ownerId, parentId);

    const handleAlphaListingClick = AlphaHandlers.useAlphaListingClick(showcaseListing, parentId, isOwnerAlpha, navigateToVdp, vdpWebsite, dispatch);

    const handleOwnerInventoryClick = AlphaHandlers.useOwnerInventoryClick(showcaseListing, ownerId, enableOwnerInventoryHandler, dealerDetailUrl, parentId);

    const handleViewPaymentDetailsClick = AlphaHandlers.useViewPaymentDetailsClick(showcaseListing, parentId);

    const handlePaymentCTAClick = AlphaHandlers.usePaymentCTAClick(showcaseListing, parentId, updateMyWallet);

    const handlePricingDetailsClick = AlphaHandlers.usePricingDetailsClick(showcaseListing, navigateToVdp, vdpWebsite);

    const handleTileClick = AlphaHandlers.useTileClick(showcaseListing, ownerId, parentId);

    const handleInventoryListingClick = AlphaHandlers.useInventoryListingClick(parentId, navigateToVdp, dispatch);

    const handleInViewCarouselChange = AlphaHandlers.useInViewCarouselChange(carouselListings);

    const handleReadyCallback = AlphaHandlers.useOnCarouselLoad(carouselListings);

    const isCompared = useCallback((listing) => compareListingIds && compareListingIds.includes(listing?.id), [compareListingIds]);

    const handleCompareListingsClick = useCallback((listing) => async (event) => {
        sendClick(compareListingsDrawerClick, event, {
            clickType: 'alpha',
            inventoryId: listing?.id,
            inheritPageData: false,
            par: 'comp_v_add',
            pixallData: {},
        });

        dispatch(userPreferencesDuck.creators.setKeys({ showDrawer: true }));
        dispatch(compareListingsDuck.creators.addToCompare(listing?.id));
        if (compareListingIds && compareListingIds.includes(listing.id)) {
            dispatch(compareListingsDuck.creators.removeFromCompare(listing?.id));
        }
    }, [dispatch, compareListingIds]);

    const handleEmailOwnerClick = useCallback(() => {
        dispatch(srpActiveEmailListingDuck.creators.setActiveResults([listingId]));
        dispatch(srpActiveInteractionDuck.creators.setKeys({
            ownerId: uiContext === 'owner' ? ownerId : '',
            parentId,
            showEmailModal: true,
            clickType: 'alpha',
        }));

    }, [dispatch, listingId, ownerId, parentId, uiContext]);

    const emailOwnerProps = useMemo(() => ({
        parentId,
        onCTAClick: handleEmailOwnerClick,
    }), [parentId, handleEmailOwnerClick]);

    const handleTelMetric = useCallback((phoneNumber) => {
        const data = {
            listing: {
                ...showcaseListing,
                vdpURL: getAbsolutePathWithListingId(window?.location?.origin, listingId),
            },
            phoneNumber,
        };
        sendClick(telMetricsPhoneTextClick, {}, data);
        // disable so that the comparison for the whole listing isn't required
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listingId]);

    const alphaTitle = uiContext === 'inventory' ? `You might like these vehicles from ${owner.name}` : 'Featured Dealer Near You';
    const listingCTA = useMemo(() => ({ href: vdpWebsite }), [vdpWebsite]);
    const onSaveToggle = useCallback((event, listing, wasSaved) => {
        dispatch(
            saveAlphaListing({
                event,
                listing,
                wasSaved,
                sendClick,
                parentId,
            })
        );
    }, [dispatch, parentId]);

    const carouselSettings = useMemo(() => ({
        onReady: handleReadyCallback,
        fullWidthScroll: true,
        visibilityThreshold: 0.9,
    }), [handleReadyCallback]);

    const personalPaymentProps = useMemo(() => ({
        fetcher: PaymentCalculationFetcher,
        paymentsEnabled: enableRetailEngine,
        ndsFeature,
    }), [enableRetailEngine, ndsFeature]);

    const paymentsCTA = useMemo(
        () => ({ onClick: handleViewPaymentDetailsClick }),
        [handleViewPaymentDetailsClick]
    );

    // Only show the placeholder when filtering if there is an existing alpha to preserve space for.
    if (isLoadingAlpha && hasAlpha) {
        return (
            <>
                <div
                    key="alphaShowcaseSponsoredText"
                    className="margin-bottom-2 text-size-100 text-right text-sm-left"
                >
                    <SponsoredText
                        height={10}
                        width={52}
                    />
                </div>
                <p
                    key="alphaShowcaseTitle"
                    className="row text-size-300 text-bold padding-bottom-3 margin-vertical-0"
                >
                    <span className="col-xs-12">
                        {alphaTitle}
                    </span>
                </p>
                <div
                    key="alphaShowcaseBody"
                    className="cursor-pointer"
                >
                    <InventoryListingPlaceholder
                        key="alpha-showcase-placeholder"
                        orientation={orientation}
                    />
                </div>
            </>
        );
    }

    /* if there is no alpha owner do no set up rest of the component or
     * if there is a sponsorship takeover active and the sponsorship query param is populated, then return nothing */
    if (!owner || (sponsorshipResultsOnly && isSponsorshipSelected)) {
        return null;
    }

    /* call to actions */

    const showCompareButton = compareToggle && compareListingIds?.length < 5;

    /* only render alpha if there is an owner object */
    return hasAlpha ? (
        <AlphaShowcase
            enableMyWallet={enableMyWallet}
            emailOwnerProps={emailOwnerProps}
            lazyLoad={false}
            listing={showcaseListing || {}}
            listings={carouselListings}
            listingsCount={similarListingsCount > 0 ? similarListingsCount : null}
            listingCTA={listingCTA}
            phoneClickHandler={handleTelMetric}
            onAlphaWalletClick={handleAlphaWalletClick}
            onCollapsibleHeaderClick={handleCollapsibleHeaderClick}
            onGetDirectionsClick={handleGetDirectionsClick}
            onAlphaListingClick={handleAlphaListingClick}
            onInventoryListingClick={handleInventoryListingClick}
            onSettle={handleInViewCarouselChange}
            onPricingDetailClick={handlePricingDetailsClick}
            onSaveToggle={isPersonalizationEnabled || authenticatedSavesOnly ? onSaveToggle : null}
            showCompareButton={showCompareButton}
            onCompareClick={handleCompareListingsClick}
            isCompared={isCompared}
            onTileClick={handleTileClick}
            onViewOwnerInventoryClick={isDdpLinkEnabled && ownerHasLocation && handleOwnerInventoryClick}
            owner={owner}
            orientation={orientation}
            parentId={parentId}
            paymentsCTA={paymentsCTA}
            personalPaymentProps={personalPaymentProps}
            savedListingIds={savedListingIds}
            showAlphaPaymentRibbon={!!enableMyWallet && !!showAlphaWalletPayment}
            title={alphaTitle}
            uiContext={uiContext}
            updateListingPayments={updateListingPayments}
            carouselSettings={carouselSettings}
            onPaymentCTAClick={handlePaymentCTAClick}
        />
    ) : null;
}

export const AlphaShowcaseContainer = memo(AlphaShowcaseContainerComponent);

export default AlphaShowcaseContainer;
