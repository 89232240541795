import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import _get from 'lodash/get';

// alerts containers
import { useDevice } from '@bonnet/core/device';

import { ClientOnly } from '@atc/react-client-only';

import { BrowserStorage } from 'atc-js';

import { useBrand } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import {
    cbhVehicleInterestsDuck,
} from '@/ducks';

import { stillInterestedAlertBeacon } from '@/fetchers';

import BrowserNotificationContainer from '@/containers/BrowserNotificationContainer';
import WalletAlertsContainer from '@/containers/WalletAlertsContainer';

import StillInterestedAlert from './StillInterestedAlert';

function SrpAlertsContainer() {

    const device = useDevice();
    const isXs = _get(device, 'is.xs', false);

    const {
        active_deals_my_wallet: [enableActiveDeals],
        offsite_browser_notifications: [, { srp_prompt: enableBrowserNotificationPrompts }],
        show_wallet_alert_notification: [showWalletAlertNotification],
        still_interested_alert: [enableStillInterestedAlert],
        disable_still_interested_alert_beacon: [disableStillInterestedAlertBeacon],
    } = useFeatures([
        'active_deals_my_wallet',
        'offsite_browser_notifications',
        'show_wallet_alert_notification',
        'still_interested_alert',
        'disable_still_interested_alert_beacon',
    ]);

    const { brand, brands } = useBrand();
    const { ATC_BRAND, KBB_BRAND } = brands;

    // wallet alerts and browser notifcation prompt local storage values
    const walletNotifCache = new BrowserStorage('WALLET_NOTIF_POPOVER', { persist: true });
    const stillInterestedVehicle = useSelector((state) => cbhVehicleInterestsDuck.selectors.getFirstVehicle(state));

    const stillInterestedVehiclesCache = new BrowserStorage('STILL_INTERESTED_VEHICLES', { persist: true });
    const cachedStillInterestedVehicles = stillInterestedVehiclesCache.getItem() || [];

    const cbhStillInterestedVehicles = (useSelector(cbhVehicleInterestsDuck.selectors.getSortedVehicles) || []).slice(0, 10);

    const alertEnabled = (showWalletAlertNotification && enableActiveDeals);
    const showWalletAlert = alertEnabled && walletNotifCache.getItem()?.show;
    const showStillInterested = enableStillInterestedAlert && stillInterestedVehicle;

    useEffect(() => {
        if (!disableStillInterestedAlertBeacon) {
        // check if we have new cbh still interested vehicles loaded
            let isEqual = true;
            for (let i = 0; i < cbhStillInterestedVehicles.length; i++) {
                if (cbhStillInterestedVehicles[i].id !== cachedStillInterestedVehicles[i]) {
                    isEqual = false;
                    break;
                }
            }

            if (!isEqual) {
                (async () => {
                    const { default: importAuthUtilityBundle } = await import('@/utilities/importAuthUtilityBundle');
                    const { getConsumerId, getUserAttributes } = await importAuthUtilityBundle();
                    const consumerId = await getConsumerId();
                    const { email } = await getUserAttributes();
                    // beacon the request
                    if ([ATC_BRAND, KBB_BRAND].includes(brand) && (consumerId || email)) {
                        stillInterestedAlertBeacon({
                            brand: brand === ATC_BRAND ? 'AT' : 'KBB',
                            vins: cbhStillInterestedVehicles.map((listing) => listing.vin),
                            consumerId,
                            email,
                        });
                    }
                })();
                // update cache
                stillInterestedVehiclesCache.setItem(cbhStillInterestedVehicles.map((listing) => listing.id));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cbhStillInterestedVehicles]);

    return (
        <ClientOnly className="padding-horizontal-2 padding-horizontal-sm-0">
            {enableBrowserNotificationPrompts && (<BrowserNotificationContainer />)}

            {(showWalletAlert && !isXs) && (
                <WalletAlertsContainer enableAlert={showWalletAlert} />
            )}
            {(!showWalletAlert && showStillInterested) && (
                <StillInterestedAlert
                    stillInterestedVehicle={stillInterestedVehicle}
                />
            )}
        </ClientOnly>
    );
}

export default SrpAlertsContainer;
