import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useDevice } from '@bonnet/next/device';

import {
    // sendClick,
    sendImpressions,
} from 'reaxl-analytics';
import {
    // buyOnlinePromoClick,
    buyOnlinePromoImpression,
} from 'reaxl-analytics-handlers';
import { PromoCard } from 'reaxl-listing';

import {
    srpFiltersDuck,
} from '@/ducks/srp';

function BuyOnlinePromoCard() {

    const device = useDevice();
    const orientation = device?.is?.xs ? 'portrait' : 'landscape';

    const filterValues = useSelector(srpFiltersDuck.selectors.getFiltersValues);

    useEffect(() => {
        sendImpressions({
            name: buyOnlinePromoImpression,
            data: {
                filterValues,
            },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (e) => {
        // fire analytics tag
        // sendClick(buyOnlinePromoClick, e, { filterValues });
    };

    return (
        <PromoCard
            buttonText="Buy My Car Online"
            className="col-xs-12"
            data-cmp="buyOnlinePromoCard"
            handleButtonClick={handleButtonClick}
            heading="Buy Your Next Car 100% Online"
            href="https://www.autotrader.com/cars-for-sale/buy-online"
            imgHeight="110"
            imgWidth="110"
            imgSrc="/content/static/img/icon/promocard/buy_online.svg"
            panelClassName="col-xs-12 col-sm-10"
            subText="In less than an hour, a new car could be in your driveway! Shop from home and get it delivered to your door or a local dealership."
            orientation={orientation}
        />
    );
}

export default BuyOnlinePromoCard;
