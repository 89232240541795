import React, { Fragment } from 'react';

import { useSelector } from 'react-redux';

import { useRouter } from 'next/router';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

import {
    Heading,
    ProgressBar,
    StarRating,
    Text,
} from 'reaxl';
import { useFeatures } from 'reaxl-features';
import { Product as ProductSchema } from 'reaxl-schema';

import { srpResultsDuck } from '@/ducks/srp';

function ConsumerRatingsContainer({ consumerRatings = {} }) {

    const stats = useSelector(srpResultsDuck.selectors.getStats) || {};
    const { derivedprice } = stats;
    const inventory = useSelector((state) => _get(srpResultsDuck.selectors.getActiveInventory(state), '[0]', {}), _isEqual);

    const { brand: [, { base_url: baseUrl = '' }] } = useFeatures(['brand']);
    const router = useRouter();
    const currentPath = baseUrl.replace(/\/$/, '') + router.pathname;

    const ratings = consumerRatings?.ratings || [];
    const overallRating = ratings.find((rating) => rating.name === 'Overall Rating');
    const ratingsBreakdown = ratings.filter((rating) => rating.name !== 'Overall Rating');

    const schemaData = {
        derivedprice,
        inventory,
        rating: {
            count: consumerRatings.numberOfReviews,
            value: overallRating?.value,
        },
        url: currentPath,
    };

    const renderHeader = (
        <>
            <Heading
                weight="bold"
                size={400}
            >
                KBB.com
                <sup>&reg;</sup>
                &nbsp;Consumer Reviews
            </Heading>

            <div className="display-flex margin-top-5">
                <div className="text-size-700 text-bold margin-right-3">
                    {overallRating?.value}
                </div>
                <div>
                    <div>
                        <StarRating
                            value={overallRating?.value}
                            color="#e8bb38"
                            className="text-size-500 margin-top-1"
                        />
                    </div>
                    <div
                        className="text-size-200 text-bold"
                        style={{ marginTop: '-6px' }}
                    >
                        Consumer Rating
                    </div>
                </div>
            </div>
        </>
    );

    const renderBreakdown = () => ratingsBreakdown.map((rating, index) => (
        <Fragment key={`consumer-rating-progress-bar=${index}`}>
            <div className="col-xs-4 margin-vertical-2 padding-0 progress-bar-consumer-rating">
                <div className="margin-bottom-1">{rating.name}</div>
                <ProgressBar
                    key={index}
                    now={Number(rating.value)}
                    max={5}
                    bsStyle="warning"
                    style={{ height: 10 }}
                />
            </div>
            <div
                className="col-xs-2"
                style={{ marginTop: 27 }}
            >
                <Text weight="bold">{rating.value}</Text>
            </div>
        </Fragment>
    ));

    return (
        <>
            <div
                id="consumer-ratings-container"
                className="container margin-vertical-4 padding-horizontal-2 padding-horizontal-sm-0"
            >
                {renderHeader}
                <div className="row margin-vertical-1 col-lg-6">
                    {renderBreakdown()}
                </div>
            </div>
            <ProductSchema
                {...schemaData}
            />
        </>
    );
}

export default React.memo(ConsumerRatingsContainer);
