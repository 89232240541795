import { useCallback } from 'react';

import _get from 'lodash/get';

import { sendClick, sendImpressions } from 'reaxl-analytics';
import {
    inventoryPaymentDetailsClick,
    listingPaymentCTAClick,
    ownerLocation,
    ownerLogoClick,
    ownerShowHideInventory,
    ownerViewInventory,
    snapshotPaymentDetailsClick,
} from 'reaxl-analytics-handlers';

import getAlphaOwnerWebsiteLink from '@/utilities/getAlphaOwnerWebsiteLink';
import handleClickableListing from '@/utilities/handleClickableListing';
import vdpUrlBuilder from '@/utilities/vdpUrlBuilder';

import {
    inventoryClick,
    inventoryImpressions,
} from '@/analytics/srpAnalyticsHandlers';

import { srpActiveInteractionDuck } from '@/ducks/srp';

const AlphaHandlers = {

    useAlphaWalletClick(updateMyWallet, showcaseListing = {}, parentId = '') {
        return useCallback((event) => {
            event.stopPropagation();
            const extra = {
                inventoryId: showcaseListing.id,
                suffix: '_a',
                par: parentId,
            };
            sendClick(snapshotPaymentDetailsClick, event, extra);
            updateMyWallet({
                listingId: showcaseListing.id,
                showPaymentDetailsModal: true,
                listingCategory: parentId,
            });
        }, [parentId, showcaseListing, updateMyWallet]);
    },

    /* handle collapsible header click for InventoryAlphaListing */
    useCollapsibleHeaderClick(showcaseListing, parentId) {
        return useCallback((event) => {
            const extra = {
                inventoryId: showcaseListing.id,
                parentId,
            };
            sendClick(ownerShowHideInventory, event, extra);
        }, [showcaseListing, parentId]);
    },

    /* handle get directions click for InventoryAlphaListing & OwnerAlphaListing */
    useGetDirectionsClick(showcaseListing, owner, ownerId, parentId) {
        return useCallback((event) => {
            const mapUrl = _get(owner, 'location.map.href', false);
            const extra = {
                ownerId,
                cmp: 'get_dir_a',
                par: parentId,
                inventoryId: showcaseListing && showcaseListing.id,
                pixallData: {
                    listingType: parentId,
                },
            };

            event.stopPropagation();
            if (mapUrl) {
                sendClick(ownerLocation, event, extra);
            }
        }, [owner, ownerId, parentId, showcaseListing]);
    },

    useAlphaListingClick(showcaseListing, parentId, isOwnerAlpha, navigateToVdp, vdpWebsite, dispatch) {
        return useCallback(async (event) => {
            dispatch(srpActiveInteractionDuck.creators.setKeys({
                showSpinningIndicator: true,
            }));

            handleClickableListing(event, () => {
                if (!isOwnerAlpha) {
                    sendClick(inventoryClick, event, {
                        inventoryId: showcaseListing.id,
                        par: parentId,
                        paginatedListingIndex: 0,
                        pixallData: {
                            eventSource: 'vehicle',
                        },
                    });
                }
            });

        }, [isOwnerAlpha, parentId, showcaseListing, dispatch]);
    },

    /* handle view owner inventory click for InventoryAlphaListing & OwnerAlphaListing in desktop view*/
    useOwnerInventoryClick(showcaseListing, ownerId, enableOwnerInventoryHandler, dealerDetailUrl, parentId) {
        return useCallback(async (event) => {
            event.stopPropagation();
            const extra = {
                co_txt_url: event.target.textContent || '',
                inventoryId: showcaseListing && showcaseListing.id,
                ownerId,
                par: parentId,
            };
            if (enableOwnerInventoryHandler) {
                await sendClick(ownerViewInventory, event, extra);
                window.location.href = dealerDetailUrl;
            }
        }, [showcaseListing, ownerId, parentId, enableOwnerInventoryHandler, dealerDetailUrl]);
    },

    /* handle view payment details click for InventoryAlphaListing */
    useViewPaymentDetailsClick(showcaseListing, parentId) {
        return useCallback((event) => {
            const extra = {
                inventoryId: showcaseListing.id,
                par: parentId,
                cmp: 'view_pymt_dtl_a',
                clickType: 'alpha',
                paginatedListingIndex: 0,
            };
            event.stopPropagation();

            sendClick(inventoryPaymentDetailsClick, event, extra);
        }, [showcaseListing, parentId]);
    },

    /* handle pricing details click for InventoryAlphaListing */
    usePricingDetailsClick(showcaseListing, navigateToVdp, vdpWebsite) {
        return useCallback((event) => {

            // needed to allow anchors within listing for SEO while using the correct handlers
            event.stopPropagation();
            event.preventDefault();

            navigateToVdp({
                url: vdpWebsite + '#pricingDetails',
                listingId: showcaseListing.id,
            });

        }, [navigateToVdp, vdpWebsite, showcaseListing]);
    },

    /* handle owner logo tile click for InventoryAlphaListing & OwnerAlphaListing */
    useTileClick(showcaseListing, ownerId, parentId) {
        return useCallback(async (event, owner) => {
            const extra = {
                ownerId,
                inventoryId: showcaseListing && showcaseListing.id,
                par: parentId,
                pixallData: {
                    listingType: parentId,
                },
            };
            event.stopPropagation();
            await sendClick(ownerLogoClick, event, extra);

            window.open(getAlphaOwnerWebsiteLink(owner));

        }, [showcaseListing, ownerId, parentId]);
    },

    useInViewCarouselChange(listings) {
        return useCallback(async (index, direction, width, selectedCells) => {
            const subArray = selectedCells.length * index;
            const listingsDisplayed = listings.slice(subArray, (subArray + selectedCells.length));
            await sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: 'alp',
                    selectedItemIds: listingsDisplayed.map(({ id }) => id),
                    pixallData: {
                        isCarousel: true,
                    },
                    startingIndex: subArray,
                },
            });
        }, [listings]);
    },

    useOnCarouselLoad(listings) {
        return useCallback(async (index, selectedCells) => {
            const subArray = selectedCells.length * index;
            const listingsDisplayed = listings.slice(subArray, (subArray + selectedCells.length));
            await sendImpressions({
                name: inventoryImpressions,
                data: {
                    cmp: 'alp',
                    selectedItemIds: listingsDisplayed.map(({ id }) => id),
                    pixallData: {
                        isCarousel: true,
                    },
                    startingIndex: subArray,
                },
            });
        }, [listings]);
    },

    useInventoryListingClick(parentId, navigateToVdp, dispatch) {
        return useCallback(async (event, listing, index, clickType = 'alpha') => {
            const baseUrl = listing?.vdpBaseUrl;
            if (typeof dispatch === 'function') {
                dispatch(srpActiveInteractionDuck.creators.setKeys({
                    showSpinningIndicator: true,
                }));
            }

            const extra = {
                par: parentId,
                inventoryId: listing.id,
                pixallData: {
                    eventSource: 'vehicle',
                },
            };

            const vdpUrl = vdpUrlBuilder({ baseUrl, clickType });

            // needed to allow anchors within listing for SEO while using the correct handlers
            event.stopPropagation();
            event.preventDefault();

            await sendClick(inventoryClick, event, extra);

            navigateToVdp({
                url: vdpUrl,
                listingId: listing.id,
            });
        }, [navigateToVdp, parentId, dispatch]);
    },

    usePaymentCTAClick(showcaseListing, parentId, updateMyWallet) {
        return useCallback((event) => {
            const extra = {
                par: parentId,
                inventoryId: showcaseListing.id,
                clickType: 'alpha',
                paginatedListingIndex: 0,
            };

            event.stopPropagation();
            sendClick(listingPaymentCTAClick, event, extra);

            updateMyWallet({
                displayModal: true,
            });
        }, [showcaseListing, parentId, updateMyWallet]);
    },
};

export default AlphaHandlers;
