import { useCallback, useContext, useMemo, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from 'next/router';

import interactionResponse from 'await-interaction-response';
import _debounce from 'lodash/debounce';

import { translateKeys } from '@atc/bonnet-parameters';

import { BrandContext } from 'reaxl-brand';
import { useFeatures } from 'reaxl-features';

import getPathSRP from '@/utilities/getPathSRP';

import { userDuck } from '@/ducks';

import {
    srpNewCarBoostDuck,
    srpNewSearchDuck,
    srpResultsDuck,
    srpSpotlightDuck,
} from '@/ducks/srp';

import ParsedQueryModule from '@/modules/ParsedQueryModule';

import useSrpSearch from './useSrpSearch';

export default function useSrpNavigation() {
    const router = useRouter();
    const currentQueryState = useSelector(ParsedQueryModule.duck.selectors.getDuckState) || {};
    const zip = useSelector(userDuck.selectors.getZip);
    const { getSrpSearchParams, getSrpSearchState } = useSrpSearch();
    const dispatch = useDispatch();
    const {
        debounced_filter_navigation: [enableDebouncedFilterNavigation, { wait }],
    } = useFeatures([
        'debounced_filter_navigation',
    ]);
    const brand = useContext(BrandContext);
    const currentQueryStateValueRef = useRef(currentQueryState);

    const debouncePushRouter = useMemo(
        () => _debounce((passingRouter, ...args) => passingRouter.push(...args), enableDebouncedFilterNavigation ? wait : 0),
        [enableDebouncedFilterNavigation, wait]
    );

    currentQueryStateValueRef.current = currentQueryState;

    const target = 'lsc';

    /*
     * This function handles the navigation of the srp and also fires a page load in analytics
     *
     * @param resetPagination
     * @param filtersValues: send in custom filter values if regular filter is not clicked
     * @param action: for how getSrpSearchState should derive filter values
     * @returns {Promise<void>}
     */
    const navigateToSrp = useCallback(async ({
        resetPagination = false,
        isNewSearch = true,
        filtersValues = {},
        action = 'merge',
        requestId = null,
        scroll = true,
    } = {}) => {
        debouncePushRouter.cancel();

        if (scroll) {
            // Pre-emptively scroll to the top to avoid layout shift from items lower on the page
            window.scrollTo(0, 0);
        }

        if (resetPagination) {
            filtersValues.firstRecord = 0;
            filtersValues.numRecords = 0;
        }

        if (isNewSearch) {
            dispatch(srpNewSearchDuck.creators.setBoolean(true));
        } else {
            dispatch(srpNewSearchDuck.creators.setBoolean(false));
        }

        // Don't actually clear listings/spotlights we will do that once we get new results back.  Just set loading so
        // so that we know to swap the current # of whatever is rendered with a placeholder.  (i.e. 2 spotlights
        // currently showing so we will render 2 placeholders because loading is true)
        dispatch(srpSpotlightDuck.creators.setLoading(true));
        dispatch(srpResultsDuck.creators.setLoading());

        // TODO should we also do the above for this?  I'm not sure if it is clearing out properly if we don't.
        dispatch(srpNewCarBoostDuck.creators.clear());
        await interactionResponse();

        // Get a query object from the srp page data
        const srpSearchState = getSrpSearchState({
            filtersValues,
            action,
            target,
        });

        // create a list of all the keys being set by the srp search
        const srpSearchParams = getSrpSearchParams();

        // [DE402038] Mapping issue between CS (packages) and LSC (packageName)
        srpSearchParams.push('packageName');

        // Persist any paramaters that are in the currentQueryState that will
        // not be defined by the incoming updated srpSearchState
        // to ensure only the current srpSearchState
        // will define the values for those parameters

        // Using useRef to avoid the previous render's stale closure of the currentQueryState variable.
        const translatedQueryState = translateKeys(currentQueryStateValueRef.current, { target });

        // On SPA sometimes very old VDP urls inject these bad parameters into state.  We need to switch to bonnet
        // VDP url building ASAP
        delete translatedQueryState.makeCode1;
        delete translatedQueryState.modelCode1;
        const nonSearchQueryState = Object.entries(translatedQueryState).reduce((acc, [key, value]) => ({
            ...acc,
            // remove any entry from the currentQueryState whose key matches with a key in the srpSearchState
            ...(srpSearchParams.includes(key) ? {} : { [key]: value }),
        }), {});

        // merge the cleaned query state and the search query state
        let query = {
            ...nonSearchQueryState,
            ...srpSearchState,
            requestId,
        };

        // if the zip has been updated then we need to remove the
        // existing city and state or they will be re-used in decoding
        // TODO: BONNET - should we scrub city and state from the query anyway?  How can we improve this?
        if (zip !== query.zip) {
            delete query.city;
            delete query.state;
        }

        // if the distance has then we need update distance same searchRadius
        if (query.distance) {
            query = {
                ...query,
                distance: query.searchRadius,
            };
        }

        // Remove bookmark id query param after filter change. It has to be identified as new search
        if (query.searchBookmarkId) {
            delete query.searchBookmarkId;
        }

        // Remove incremental time query param after filter change. It has to be identified as new search
        if (query.incremental) {
            delete query.incremental;
        }

        // VDP redirect message should disappear after filter change
        if (query.redirectExpiredPage) {
            delete query.redirectExpiredPage;
        }

        // Remove year param that is appended by Build and Price page, srp will use startYear and endYear
        if (query.year) {
            delete query.year;
        }

        // Remove owner id param added by Build and Price page
        if (query.ownerId) {
            delete query.ownerId;
        }

        // TODO [US1123588] Re-Migrate URL prams from CS -> LSC once all /base services are migrated
        const urlTarget = 'lsc';
        const url = await getPathSRP(query, {
            target: urlTarget,
            brand,
            // nextjs router push appends basePath
            // as the result of this call is an imperative router push
            // we shall remove the basePath
            basePath: false,
        });

        debouncePushRouter(router, url, undefined, { scroll });

    }, [brand, dispatch, getSrpSearchParams, getSrpSearchState, debouncePushRouter, router, zip]);

    return navigateToSrp;
}
