import _get from 'lodash/get';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';

import withBonnetDataIsland from '@atc/bonnet-ctx-data-island';
import { getKeys } from '@atc/bonnet-parameters';
import { getReference } from '@atc/bonnet-reference';

import { brands } from 'reaxl-brand';
import { getActiveExperiments } from 'reaxl-optimizely';

import fordSeoUrl from '@/config/seo-config-url/branded/ford';

const getHomeServices = (homeServices = {}) => {
    const dhsList = [];
    const { vehicleDelivery, testDrive, virtualTour } = homeServices;

    if (vehicleDelivery) {
        dhsList.push('dhs:deliver');
    }
    if (testDrive) {
        dhsList.push('dhs:test drive');
    }
    if (virtualTour) {
        dhsList.push('dhs:video');
    }

    return dhsList.join('|');
};

const getSRPPageName = (listingCount) => (listingCount === '0' ? 'Search Results Page - NR' : 'Search Results Page');

const getFuelEconomy = (mpgCity, mpgHighway) => {
    const fuelEconomy = [];
    if (mpgCity) {
        fuelEconomy.push(mpgCity + ' mpg city');
    }
    if (mpgHighway) {
        fuelEconomy.push(mpgHighway + ' mpg hwy');
    }
    return fuelEconomy;
};

const getSrpPg = (resultCount) => {
    let pg;
    if (resultCount === 0) {
        pg = 'fyc_ncf';
    } else {
        pg = 'fyc_srl';
    }

    return pg;
};

const getVdpPg = (id, query) => {
    let pg = 'fyc_vdp';

    if (!id) {
        const { zip } = query;
        const make = query.makeCodeList;
        const model = query.modelCodeList;

        pg = 'fyc_exvdp_nmmz';

        if (zip && make && model) {
            pg = 'fyc_exvdp_mmz';
        } else if (!zip && make && model) {
            pg = 'fyc_exvdp_make_mdl';
        }
    }

    return pg;
};

const getKbbSrpPageName = (listingTypes = []) => {
    listingTypes = [].concat(listingTypes);
    listingTypes = listingTypes.map((value) => value.toLowerCase());
    const usedListingType = listingTypes.length > 0 && (listingTypes.includes('used') || listingTypes.includes('certified')) && !listingTypes.includes('new');
    return usedListingType ? 'classlistused' : 'classlistnew';
};

const getKbbVdpPageName = (id = '', listingType = '') => {
    if (id) {
        return listingType.toUpperCase() === 'NEW' ? 'classdetailnew' : 'classdetailused';
    }

    return 'classdetailexpired';
};

const getPn = (numRecords, firstRecord = 0) => {
    let pn;
    if (firstRecord && numRecords) {
        if ((firstRecord % numRecords) === 0) {
            pn = String(firstRecord / numRecords);
        } else {
            pn = String((firstRecord / numRecords) + 1);
        }
    } else {
        pn = '0';
    }

    return pn;
};

const getTransmission = (transmissionCodes) => {
    transmissionCodes = !Array.isArray(transmissionCodes) ? [transmissionCodes] : transmissionCodes;
    const transmission = [];
    const codeMapping = {
        AUT: 'Automatic',
        MAN: 'Manual',
    };

    transmissionCodes.forEach((code) => transmission.push(codeMapping[code]));

    return transmission[0] ? transmission[0] : '';
};

const isAdvanced = (query = {}) => !!(query.transmissionCodes
    || query.maxMileage
    || query.engineCodes
    || query.extColorsSimple
    || query.driveGroup
    || query.fuelTypeGroup
    || query.featureCodes
    || query.keywordPhrases);

const getVDPBodyCodes = async (brand, base = {}) => {
    const bodyCodeList = [].concat(base.bodyStyleCodes);

    if (brand === brands.KBB_BRAND) {
        const kbbCodes = [];
        const { payload: bodyCodePayload = [] } = await getReference('vehicleStyleCode');
        bodyCodeList.forEach((bodyCode) => {
            const { kbbCode: bodyCodeId } = bodyCodePayload.find(({ code }) => code.includes(bodyCode)) || {};
            kbbCodes.push(bodyCodeId);
        });
        return kbbCodes;
    }

    return bodyCodeList;
};

const getSRPMakeCodes = async (query, brand, queryKeys) => {
    let makeCodeList = [];

    if (query[queryKeys.makeCodeKey]) {
        makeCodeList = [].concat(query[queryKeys.makeCodeKey]);
    }

    if (makeCodeList.length > 0) {
        if (brand === brands.KBB_BRAND) {
            const kbbCodes = [];
            const { payload: makePayload = [] } = await getReference('makeCode');
            makeCodeList.forEach((makeCode) => {
                const { code: makeId } = makePayload.find(({ code }) => makeCode === code) || {};
                kbbCodes.push(makeId);
            });
            return kbbCodes;
        }
        return makeCodeList;
    }

    return ['any'];
};

const getSRPModelCodes = async (query, brand, queryKeys) => {
    let makeCodeList = [];
    let modelCodeList = [];

    if (query[queryKeys.makeCodeKey] && query[queryKeys.modelCodeKey]) {
        makeCodeList = [].concat(query[queryKeys.makeCodeKey]);
        modelCodeList = [].concat(query[queryKeys.modelCodeKey]);
    }

    if (modelCodeList.length > 0 && makeCodeList.length > 0) {
        if (brand === brands.KBB_BRAND) {
            const kbbCodes = [];
            makeCodeList.forEach(async (makeCode) => {
                const { payload: modelPayload = [] } = await getReference('modelCode', { makeCode });
                modelCodeList.forEach((modelCode) => {
                    const { code: modelId } = modelPayload.find(({ code }) => modelCode === code) || {};
                    if (modelId) {
                        kbbCodes.push(modelId);
                    }
                });
            });
            return kbbCodes;
        }

        return modelCodeList;
    }
    return ['any'];
};

const getSRPBodyCodes = async (query, brand, queryKeys) => {
    let bodyCodeList = [];
    if (query[queryKeys.vehicleStyleCodeKey]) {
        bodyCodeList = [].concat(query[queryKeys.vehicleStyleCodeKey]);
    }

    if (brand === brands.KBB_BRAND) {
        const kbbCodes = [];
        const { payload: bodyCodePayload = [] } = await getReference('vehicleStyleCode');
        bodyCodeList.forEach((bodyCode) => {
            const { kbbCode: bodyCodeId } = bodyCodePayload.find(({ code }) => bodyCode === code) || {};
            kbbCodes.push(bodyCodeId);
        });
        return kbbCodes;
    }

    return bodyCodeList;
};

const getVDPMakeCodes = async (brand, base = {}) => {
    const makeCodeList = [].concat(base.makeCode);

    if (makeCodeList.length > 0) {
        if (brand === brands.KBB_BRAND) {
            const kbbCodes = [];
            const { payload: makePayload = [] } = await getReference('makeCode');
            makeCodeList.forEach((makeCode) => {
                const { kbbName: makeId } = makePayload.find(({ code }) => makeCode === code) || {};
                kbbCodes.push(makeId);
            });
            return kbbCodes;
        }
        return makeCodeList;
    }

    return ['any'];
};

const getVDPModelCodes = async (brand, base = {}) => {
    const makeCodeList = [].concat(base.makeCode);
    const modelCodeList = [].concat(base.modelCode);

    if (modelCodeList.length > 0 && makeCodeList.length > 0) {
        if (brand === brands.KBB_BRAND) {
            const kbbCodes = [];
            makeCodeList.forEach(async (makeCode) => {
                const { payload: modelPayload = [] } = await getReference('modelCode', { makeCode });
                modelCodeList.forEach((modelCode) => {
                    const { kbbName: modelId } = modelPayload.find(({ code }) => modelCode === code) || {};
                    if (modelId) {
                        kbbCodes.push(modelId);
                    }
                });
            });
            return kbbCodes;
        }

        return modelCodeList;
    }
    return ['any'];
};

const getVDPSearchTypes = (brand, base = {}) => {
    const searchTypes = [].concat(base.type);

    if (searchTypes.length > 0) {
        if (brand === brands.KBB_BRAND || searchTypes.length <= 1) {
            return searchTypes;
        }
        return ['both'];
    }
    return ['all'];
};

const getVDPFuelType = async (base = {}) => {
    const fuel = base.specifications?.fuelType?.value;
    const { payload: fuelPayload = [] } = await getReference('fuelTypeGroup');
    const { code: fuelCode } = fuelPayload.find(({ name }) => fuel === name) || {};

    return fuelCode;
};

const getSRPFuelType = async (query = {}) => {
    let fuelGroup = [];
    if (query.fuelTypeGroup) {
        fuelGroup = [].concat(query.fuelTypeGroup);
    }
    const fuelCodes = [];
    const { payload: fuelPayload = [] } = await getReference('fuelTypeGroup');
    fuelGroup.forEach(async (fuel) => {
        const { name: fuelCode } = fuelPayload.find(({ code }) => fuel === code) || {};
        if (fuelCode) {
            fuelCodes.push(fuelCode);
        }
    });

    return fuelCodes;
};

const getDataForSRP = async (brand, logDomain, queryKeys, query = {}, ctxdata = {}) => {

    const makeCode = await getSRPMakeCodes(query, brand, queryKeys);
    const modelCode = await getSRPModelCodes(query, brand, queryKeys);
    const transmissionCode = query[queryKeys.transmissionCodeKey];
    const totalBoostCount = ctxdata?.totalBoostCount;

    const data = {
        page: {
            site: {
                domainKey: 'srp',
                superSection: '',
                section: 'Search',
                pageName: getSRPPageName(ctxdata.totalResultCount),
            },
            vehicle: {
                body_code: await getSRPBodyCodes(query, brand, queryKeys),
                make: makeCode,
                model: modelCode,
            },
            owner: {
                dealer_id: ctxdata.listings && ctxdata.listings.map((listing) => listing.owner),
                seller_type: query[queryKeys.sellerTypeKey] || 'b',
            },
            BIRF: {
                logDomain,
                make: makeCode,
                model: modelCode,
                disableImpressions: 'false',
                pg: getSrpPg(ctxdata.totalResultCount),
                Log: '',
                disableGenericEvents: 'true',
                end_year: query.endYear,
                first_rec_num: query.firstRecord || '0',
                num_records: query.numRecords,
                pn: getPn(query.numRecords, query.firstRecord),
                ssrp: query.specialId ? 'y' : 'n',
                start_year: query.startYear,
                tlc: ctxdata.totalResultCount,
                tvc: ctxdata.totalResultCount,
                pricesOnly: '1',
                marketExtension: query.marketExtension,
                fuel: await getSRPFuelType(query),
                advanced: isAdvanced(query) ? 'y' : 'n',
                nat: 'n',
                geo: 'n',
                transmission: getTransmission(transmissionCode),
                experimentBoosts: totalBoostCount || 0,
                pg_id: brand === brands.KBB_BRAND ? getKbbSrpPageName(query.listingType || ctxdata?.filters?.listingTypes?.value) : getSrpPg(ctxdata.totalResultCount),
                test_name: getActiveExperiments(),
            },
            doubleclick: {
                fldlght: {
                    ON: 'Y',
                    SRC: '3135983',
                    Type: 'autot826',
                    CAT: 'searc192',
                    ORD: String(parseInt(Math.random() * 10000000000, 10)),
                },
            },
        },
    };

    return data;
};

const getVehicleDataForVDP = async (brand, logDomain, base = {}, query = {}, ctxdata = {}) => {
    if (!base?.type && base?.listingType) {
        base.type = base.listingType.charAt(0) + base.listingType.slice(1).toLowerCase();
    }
    if (!base?.bodyStyleCodes && base?.bodyStyles) {
        base.bodyStyleCodes = base.bodyStyles.map((style) => style.code);
    }
    if (base?.make?.name) {
        base.make = base.make.name;
    }
    if (base?.model?.name) {
        base.model = base.model.name;
    }

    const data = {
        page: {
            site: {
                domainKey: 'vdp',
                superSection: '',
                section: 'Vehicle Details',
                pageName: 'Vehicle Detail Page',
            },
            owner: {
                dealer_id: base.ownerId,
                seller_type: query.sellerTypes || 'b',
                dealerHomeServices: getHomeServices(ctxdata.owner?.homeServices),
            },
            vehicle: {
                body_code: await getVDPBodyCodes(brand, base),
                car_year: base.year,
                car_id: base.id,
                fuel: await getVDPFuelType(base),
                make: await getVDPMakeCodes(brand, base),
                model: await getVDPModelCodes(brand, base),
                series: [], // empty in current state vdp?
                trim: base.trim,
                price: base.pricingDetail?.salePrice,
                search_type: getVDPSearchTypes(brand, base),
                Car_Type: base.type,
                color: [base.specifications?.exterior?.value],
                chromeStyleId: base.styleId,
                vin: base.vin,
                salesPrice: base.pricingDetail?.salesPrice,
                msrp: base.pricingDetail?.msrp,
                makeName: [base.make],
                modelName: [base.model],
                listingPriorityType: base.priority,
                stockNumber: base.stockNumber === 'Information Unavailable' ? '' : base.stockNumber,
                odometer: (base.specifications?.mileage?.value ?? 0) + ' mi',
                fuelEconomy: getFuelEconomy(base.mpgCity, base.mpgHighway),
                srch_type: getVDPSearchTypes(brand, base),
            },
            BIRF: {
                logDomain,
                pg: getVdpPg(base.id, query),
                Log: '',
                search_type: base.type,
                year: base.year,
                c: base.ownerId,
                ct: base.type && base.type.substring(0, 1).toLowerCase(),
                v: base.id,
                oemaa: base.ownerId,
                s: base.specialId,
                spin: 'n',
                cross_promo_special_id: '',
                vid: ctxdata.videos ? 'y' : 'n',
                vid_id: ctxdata.videos && ctxdata.videos.map((video) => String(video.videoId)),
                makeName: [base.make],
                modelName: [base.model],
                disableGenericEvents: 'true',
                disableImpressions: 'false',
                pg_id: brand === brands.KBB_BRAND ? getKbbVdpPageName(base.id, base.type) : getVdpPg(base.id, query),
                test_name: getActiveExperiments(),
            },
            doubleclick: {
                fldlght: {
                    ON: 'Y',
                    SRC: '3135983',
                    Type: 'autot826',
                    CAT: 'vehic678',
                    ORD: String(parseInt(Math.random() * 10000000000, 10)),
                },
            },
        },
    };

    return data;
};

const getDataForASF = () => {
    const data = {
        page: {
            BIRF: {
                pg: 'fyc_sf',
            },
        },
    };

    return data;
};

const getDataForFBA = () => ({
    page: {
        BIRF: {
            pg: 'fyc_lnd_fba',
        },
    },
});

const getDataForBAP = () => ({
    page: {
        site: {
            domainKey: 'bap',
            pageName: 'bld_and_pr',
        },
        BIRF: {
            pg: 'bld_and_pr',
            pg_id: 'bld_and_pr',
        },
    },
});

export default function withDataIsland() {

    return async (ctx) => {
        const {
            pageName = '',
            query = {},
        } = ctx;
        const brand = _get(ctx, 'data.brand');

        const target = 'lsc';

        const queryKeys = {
            makeCodeKey: getKeys('makeCode')[target],
            modelCodeKey: getKeys('modelCode')[target],
            seriesCodeKey: getKeys('seriesCode')[target],
            sellerTypeKey: getKeys('sellerType')[target],
            transmissionCodeKey: getKeys('transmissionCode')[target],
            vehicleStyleCodeKey: getKeys('vehicleStyleCode')[target],
        };

        const data = {};

        let logDomain = '';
        if (ctx.req) {
            // on serverside requests, get the log domain from the env because the window doesnt yet exist
            logDomain = process.env.logDomain;
        } else {
            // on client side requests get the log domain from the window because the process.env isnt available on client side

            const { location: { hostname } } = window;

            const isProd = ['www.autotrader.com', 'awscs.autotrader.com', 'www.kbb.com', fordSeoUrl.wwwUrl].includes(hostname);
            logDomain = isProd ? 'https://www.autotrader.com' : 'https://cs-master.awsacsnp.autotrader.com';
        }

        switch (pageName) {
            case 'vdp': {
                const baseData = _get(ctx, 'data.base.listings[0]', '');
                const vdpData = await getVehicleDataForVDP(brand, logDomain, baseData, query, ctx.data);
                Object.assign(data, vdpData);
                break;
            }
            case 'srp': {
                const srpData = await getDataForSRP(brand, logDomain, queryKeys, query, ctx.data);
                Object.assign(data, srpData);
                break;
            }
            case 'sfp': {
                const asfData = getDataForASF();
                Object.assign(data, asfData);
                break;
            }
            case 'fordlanding': {
                const fbaData = getDataForFBA();
                Object.assign(data, fbaData);
                break;
            }

            case 'bap': {
                const bapData = getDataForBAP();
                Object.assign(data, bapData);
                break;
            }

            default:
        }

        await withCtxMiddleware([
            withBonnetDataIsland({ data }),
        ], ctx);
    };
}
