export default function withExperiences() {
    return async (ctx) => {

        const {
            srp_buy_online: [enableSrpBuyOnline],
        } = ctx.useFeatures(['srp_buy_online']);

        // set up correct srp experience on page load
        if (enableSrpBuyOnline && typeof window === 'undefined') {
            const homeServicesValues = typeof ctx.query?.homeServices === 'string' ? [ctx.query?.homeServices]
                : ctx.query?.homeServices;
            const includesBuyOnline = homeServicesValues && homeServicesValues.includes('BUY_ONLINE');

            if (ctx.query.experience === 'buy-online' && !includesBuyOnline) {
                ctx.query.homeServices = homeServicesValues ? [...homeServicesValues, 'BUY_ONLINE'] : ['BUY_ONLINE'];
            }
            if (!ctx.query.experience && includesBuyOnline) {
                ctx.query.experience = 'buy-online';
            }
        }
    };
}
