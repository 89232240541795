import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { formattedNumber } from 'atc-js';

import {
    AccordionPanel,
    Select,
} from 'reaxl';
import {
    FilterTitle,
} from 'reaxl-filters';

import { srpFiltersDuck } from '@/ducks/srp';

import FilterPlaceholder from './FilterPlaceholder';

function MileageFilter({
    onOptionChange = () => { },
    handleShowExpansion = () => { },
}) {
    const mileageOptions = useSelector(srpFiltersDuck.selectors.getMileageOptions);
    const mileageValue = useSelector(srpFiltersDuck.selectors.getMileageValue);

    const filterName = 'mileage';
    const isExpanded = useSelector((state) => srpFiltersDuck.selectors.isExpansionActive(state, filterName));
    const showFilter = isExpanded && Object.keys(mileageOptions).length !== 0;

    const title = (
        <FilterTitle
            key="mileageFilterTitle"
            title="Mileage"
        />
    );

    const getFilterOptions = (options) => (options ? options?.map((option) => ({
        ...option,
        label: option.count || option.count === 0 ? `${option.label} (${formattedNumber({ value: option.count })})` : option.label,
    })) : []);

    // expand the panel if active options inside of it
    useEffect(() => {
        if (mileageValue.length) {
            handleShowExpansion(filterName);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mileageValue.length]);

    const mileageFilterProps = {
        'aria-label': 'Mileage',
    };

    const mileageFilter = (
        <Select
            name={filterName}
            options={getFilterOptions(mileageOptions?.options)}
            onChange={(event) => onOptionChange(event, mileageOptions)}
            value={mileageValue}
            {...mileageFilterProps}
        />
    );

    return (
        <AccordionPanel
            eventKey={filterName}
            data-cmp="MileageFilter"
            title={title}
            contentPadding={5}
        >
            {!showFilter && <FilterPlaceholder uniqueKey={filterName} />}
            {showFilter && mileageFilter}
        </AccordionPanel>
    );
}

export default MileageFilter;
